@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// --------------------
// XENIAL DESIGN SYSTEM
// --------------------

// COLORS

// Brand Primary
$primary: #0b59de; // Color marca, botones, backgrounds, iconos
$primary-lightest: #cedef8;
$primary-darken: darken($primary, 5%);
$primary-lighten: lighten($primary, 30%);

// Brand Secondary
$primary-dark: #0c2340;
$yellow: #f5b930;
$green: #19cfbd;
$green-lightest: #e8faf8;
$peach: #ffb387;

// Brand Tertiary
$violet: #a4a2ce;
$laurel: #a3b6a2;
$coral: #e88087;
$currant: #635063;

// Seller brand
$green-darken: darken($green, 5%);
$green-lighten: lighten($green, 5%);
$green-lightest: #e8faf8;

// Neutral
$neutral: (
  00: #fff,
  05: #f2f2f2,
  10: #e6e6e6,
  // dividers, box border
  20: #cdcdcd,
  // input border
  40: #9a9a9a,
  60: #686868,
  80: #282828,
);

$neutral-00: #fff;
$neutral-05: #f2f2f2;
$neutral-10: #e6e6e6; // Dividers y bordes de cajas
$neutral-20: #cdcdcd; // Input bordes
$neutral-40: #9a9a9a;
$neutral-60: #686868;
$neutral-80: #282828;

// Feedback
// Formularios, elementos de diálogo, toast, iconos...

// Success
$success-light: #d3e9ce;
$success-medium: #49a885;
$success-dark: #0d6846;

// Warning
$warning-light: #fef1ce;
$warning-medium: #f2b109;
$warning-dark: #ba8316;

// Info
$info-light: #dbedfb;
$info-medium: #4f9bd1;
$info-dark: #2a6086;

// Error
$error-light: #f3dddd;
$error-medium: #d24444;
$error-dark: #8f2f2f;

// Backgrounds
$bg-primary: #fff;
$bg-secondary: #f2f2f2;
$bg-helper: #f3f6fe;

// Opacity

$opacity-dark: $neutral-80;
$opacity-dark-10: rgba(
  $color: $opacity-dark,
  $alpha: 0.1,
);
$opacity-dark-20: rgba(
  $color: $opacity-dark,
  $alpha: 0.2,
);
$opacity-dark-40: rgba(
  $color: $opacity-dark,
  $alpha: 0.4,
);
$opacity-dark-80: rgba(
  $color: $opacity-dark,
  $alpha: 0.8,
);

$opacity-light: $neutral-00;
$opacity-light-10: rgba(
  $color: $opacity-light,
  $alpha: 0.1,
);
$opacity-light-20: rgba(
  $color: $opacity-light,
  $alpha: 0.2,
);
$opacity-light-40: rgba(
  $color: $opacity-light,
  $alpha: 0.4,
);
$opacity-light-80: rgba(
  $color: $opacity-light,
  $alpha: 0.8,
);

// Text
$text-white: map-get($neutral, 00);
$text-light: map-get($neutral, 40);
$text-medium: map-get($neutral, 60);
$text-dark: map-get($neutral, 80);
$text-link: $primary;

// TYPOGRAPHY

// Weights
$font-weight: (
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
);

// Font family
@mixin font-awesome-regular() {
  font-family: 'Font Awesome 6 Pro';
  font-weight: map-get($font-weight, regular);
}

@mixin font-awesome-solid() {
  font-family: 'Font Awesome 6 Pro';
  font-weight: map-get($font-weight, bold);
}

$font-icon: 'Font Awesome 6 Pro Regular';
$font-icon-solid: 'Font Awesome 6 Pro Solid';
$font-family-base: 'Roboto', sans-serif;

// Sizes

$font-size: (
  caption: 10px,
  small: 12px,
  body: 14px,
  medium: 16px,
  large: 20px,
  xlarge: 24px,
);

$heading-size-1: 48px;
$heading-size-2: 40px;
$heading-size-3: 32px;
$heading-size-4: 24px;
$heading-size-5: 18px;

// spaces
$space: (
  base: 4px,
  base-2: 8px,
  base-3: 12px,
  base-4: 16px,
  base-5: 20px,
  base-6: 24px,
  base-8: 32px,
  base-9: 36px,
  base-10: 40px,
);

// Custom classes -- move to best placement

.xen-heading-1 {
  font-size: $heading-size-1;
  font-weight: map-get($font-weight, medium);
  font-family: 'Inter', 'Helvetica Neue', sans-serif !important;
}

.xen-heading-3 {
  font-size: $heading-size-3;
  font-weight: map-get($font-weight, medium);
  font-family: 'Inter', 'Helvetica Neue', sans-serif !important;
}

.xen-heading-4 {
  font-size: $heading-size-4;
  font-weight: map-get($font-weight, medium);
  font-family: 'Inter', 'Helvetica Neue', sans-serif !important;
}

.xen-heading-5 {
  font-size: $heading-size-5;
  font-weight: map-get($font-weight, medium);
  font-family: 'Inter', 'Helvetica Neue', sans-serif !important;
}

// Bootstrap var

$theme-colors: (
  primary: $primary,
  secondary: $text-medium,
  warning: $warning-medium,
  success: $success-medium,
  danger: $error-medium,
);

// Gutters

$grid-gutter-width: 1.5rem !default;

$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
);

// border

$border-tgx: 1px solid $neutral-10;

// Box shadow charts

$tgx-shadow: rgba($neutral-60, $alpha: 0.1);
