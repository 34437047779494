/*******************************
         Theme Overrides
*******************************/
/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;

  a {
    color: $primary;
  }

  .bg-body {
    background-color: $neutral-05 !important;
  }
}

// Scrollbar

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $neutral-05;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: $neutral-05;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $text-medium;
  border: 3px solid $neutral-05;
}

//OVERRIDES

// font-size

.tgx-fontsize-primary {
  font-size: map-get($font-size, medium);
}

.tgx-fontsize-secondary {
  font-size: map-get($font-size, body);
}

.tgx-fontsize-tertiary {
  font-size: map-get($font-size, small);
}

// color text out of cards

.tgx_c_out_card {
  color: $text-medium;
}

// box-shadow
.tgx-shadow {
  box-shadow: 0 0 9px 0 $tgx-shadow;
  border-radius: 3px;
}

// text secondary

.text-secondary {
  color: $neutral-60 !important;
}

// font-weight
.fw-500 {
  font-weight: map-get($font-weight, medium);
}

// borders
.border,
.border-bottom,
.border-top,
.border-end,
.border-start {
  border-color: $neutral-10 !important;
}

// primeng font
.p-component,
.p-inputtext {
  font-family: 'Roboto', sans-serif !important;
}

// Search wrap
.search-wrap {
  padding: map-get($space, base-4);
  background-color: $bg-secondary;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    bottom: -23px;
    left: 0;
    position: absolute;
    border-style: solid;
    border-width: 25px 25px 0 0;
    border-color: $bg-secondary transparent transparent transparent;
  }
}

// User Setting dropdown links
.settings {
  .xen-link {
    padding: 14px;

    &:hover {
      background-color: $neutral-05;
    }

    &__icon {
      font-size: 16px !important;
      color: $text-dark !important;
      gap: 8px !important;
    }
  }
}

// Margin 0 for sistem alerts
.admin-alert {
  .alert {
    margin-bottom: 0;
  }
}

// Stats table
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.xen-filter-heading {
  @extend .xen-heading-5;
  color: $text-dark;

  &::before {
    @include font-awesome-regular();
    content: '\e0ad';
    display: inline-block;
    margin-right: map-get($space, base-2);
  }
}

.google-visualization-tooltip {
  width: 200px;
}

// Ask IA button
#kapa-widget-container {
  .mantine-f1fjxp {
    background-color: transparent !important;
    box-shadow: none !important;
    width: 9rem !important;
  }

  .mantine-1uwoaul {
    display: none !important;
  }

  .mantine-yxmaw9 {
    width: 9rem !important;
  }

  .mantine-1sc70ew {
    width: 9rem !important;
    height: 4rem !important;
    object-fit: scale-down !important;
  }
}

// Dropdown tables (ellipsis & style dropdown)
.tgx-dropdown-ellipsis {
  .dropdown-toggle {
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    i {
      color: $primary-dark;
    }

    &::after {
      display: none;
    }

    &:hover {
      background-color: #f4f6f7;
      transition: 0.1s all linear;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      font-size: map-get($font-size, body);
      padding: 0;

      &:hover,
      &:active,
      &:focus,
      .active {
        background-color: #e9ecef !important;
      }

      a,
      button {
        display: block;
        padding: 10px;
        text-decoration: none;
        color: initial;
        cursor: pointer;
        font-family: $font-family-base;
      }
    }
  }
}

// Tooltip (XENIAL component) - (ENGFRONT-3447)
.xen-tooltip {
  .tooltip-inner {
    background-color: rgba($neutral-80, 0.9);
    border-radius: 2px;
    font-size: map-get($font-size, small) !important;
    //min-width:200px;
  }

  &.bs-tooltip-end {
    .tooltip-arrow::before {
      border-right-color: rgba($neutral-80, 0.9);
    }
  }

  &.bs-tooltip-start {
    .tooltip-arrow::before {
      border-left-color: rgba($neutral-80, 0.9);
    }
  }

  &.bs-tooltip-top {
    .tooltip-arrow::before {
      border-top-color: rgba($neutral-80, 0.9);
    }
  }

  &.bs-tooltip-bottom {
    .tooltip-arrow::before {
      border-bottom-color: rgba($neutral-80, 0.9);
    }
  }
}

// Xen-popover (XENIAL component) - (ENGFRONT-3443)
.xen-popover {
  padding: map-get($space, base-3);
  border-color: $neutral-10 !important;
  border-radius: 2px;
  max-width: 450px !important;

  .popover-header {
    background: none;
    padding: 0 0 8px 0;
    font-size: map-get($font-size, body);
    font-weight: map-get($font-weight, medium);
    border-color: $neutral-10 !important;
  }

  .popover-body {
    padding: 8px 0 0 0;
    font-size: map-get($font-size, body);
    font-weight: map-get($font-weight, regular);
  }

  &.bs-popover-bottom {
    .popover-header {
      &::before {
        display: none;
      }
    }
  }
}

// B2B popover
.popover-pax {
  background-color: $neutral-00;
  border: 1px solid $neutral-10;
  padding: map-get($space, base-3);
  border-radius: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;

  &__body {
    &__wrapper {
      .title {
        font-size: map-get($font-size, body);
        font-weight: map-get($font-weight, medium);
      }

      .pax {
        &__item {
          width: 60px;
        }
      }
    }
  }
}

// Stats popover
.popover-time {
  min-width: 768px;

  .popover-body {
    padding: 0;
  }

  .title {
    font-size: $heading-size-5;
    font-weight: map-get($font-weight, medium);
  }

  .quick-wrap {
    background-color: $bg-helper;
    position: relative;
    height: 100%;
    padding: map-get($space, base-3);

    .subtitle {
      font-size: map-get($font-size, medium);
      font-weight: map-get($font-weight, regular);
    }

    .list-checks {
      column-count: 2;

      @media screen and (max-width: 425px) {
        column-count: 1;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 20px 20px;
      border-color: transparent transparent transparent $bg-helper;
      position: absolute;
      right: -19px;
      top: 0;
    }
  }
}

// Calendar (XENIAL component) - (ENGFRONT - 3480)
$datepicker: '.xen-datepicker';

#{$datepicker} {
  width: 100%;

  .p-inputtext {
    border-radius: 2px;
    height: 40px;
    border-color: $neutral-20;
    width: 100%;
  }

  &.--is-error {
    .p-inputtext {
      border-color: $error-medium;
    }

    &::before {
      content: '\f06a';
      display: inline-block;
      color: $error-medium;
      position: absolute;
      @include font-awesome-regular();
      font-size: map-get($font-size, body);
      top: 10px;
      right: 10px;
    }
  }

  &.--is-success {
    .p-inputtext {
      border-color: $success-medium;
    }

    &::before {
      content: '\f00c';
      display: inline-block;
      color: $success-medium;
      position: absolute;
      @include font-awesome-regular();
      font-size: map-get($font-size, body);
      top: 10px;
      right: 10px;
    }
  }
}

ngb-datepicker {
  border-radius: 2px;

  .ngb-dp-header,
  .ngb-dp-weekdays {
    background-color: $bg-secondary;
  }

  .ngb-dp-weekdays {
    margin-bottom: map-get($space, base);
  }

  .text-muted {
    color: $text-medium !important;
  }

  .btn-light {
    color: $text-dark;
  }

  .ngb-dp-navigation-select {
    select.form-select {
      border-radius: 2px;
    }
  }

  .ngb-dp-weekday {
    color: $primary;
    font-style: normal;
  }

  .ngb-dp-navigation-chevron {
    border-width: 2px 2px 0 0;
    color: $neutral-60;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Structure cleaning scss (ENGFRONT-3435)
//
// _card.scss (ENGFRONT-3431) - (Dashboard & connections home)/////////////////////////////////////////////////////////
.tgx-card {
  .card-header {
    min-height: 68px;
    padding: 16px 0;
    background-color: $text-white;
    // display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-color: #e4e8ec !important;

    h3 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 0.9rem;
      white-space: nowrap;
      margin-bottom: 0;
      color: $text-medium;
    }

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  .card-body {
    border-color: #e4e8ec !important;
    padding: 16px 0;
  }

  .card-footer {
    background-color: $text-white;
    padding: 16px;
    border-color: #e4e8ec !important;
  }
}

// _collapse.scss (ENGFRONT-3437) - (Alerts, Stepper signup v3 & Tickets v2)////////////////////////////////////////////
//
// Alerts
.tgx-acc-alerts {
  .accordion {
    .accordion-item {
      border: none;

      .accordion-header {
        background: none;
        padding: 0;

        .accordion-button {
          text-decoration: none;
          color: $primary-dark;
          width: 100%;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-size: 0.9rem;
          font-weight: 500;
          padding: 10px;
          background: none;

          &:focus {
            box-shadow: none;
          }

          &.collapsed {
            border-bottom: $border-tgx;
          }

          .acc-title {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: $primary-dark !important;
          }
        }

        .accordion-button:not(.collapsed)::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
          transform: rotate(-180deg);
        }
      }

      .collapse {
        .accordion-body {
          border-bottom: $border-tgx;
        }
      }

      &:last-child {
        .accordion-button {
          &.collapsed {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

// Stepper signup v3
.tgx-stepper-v3 {
  --bs-accordion-border-width: 0px !important;

  .accordion-item {
    background: none;

    .accordion-header {
      .accordion-button {
        background: none;

        &::after {
          display: none;
        }

        &_step-info {
          &_title,
          &_subtitle {
            color: $neutral-00;
          }

          &_title {
            font-size: 18px;
            font-weight: 400;
          }

          &_subtitle {
            font-size: 14px;
            opacity: 0.5;
          }
        }

        &_step-check {
          color: $neutral-00;
          padding: 13px;
          border-radius: 50%;
          border: 1px solid $neutral-00;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 12px;
          }
        }

        &[aria-expanded='true'],
        &--is-finish {
          .accordion-button_step-check {
            background-color: $neutral-00;

            &_icon {
              color: $primary;
            }
          }
        }

        &_edit {
          a {
            color: $neutral-00;
          }
        }
      }
    }

    .vr-container {
      align-self: normal;

      .vr {
        opacity: 1 !important;
        background-color: $neutral-00 !important;
        margin-left: 19px;
        width: 2px;
        min-height: 24px;
        align-self: auto;
      }
    }

    .accordion-collapse {
      margin-left: 40px;
      border: none;

      .accordion-body {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba($color: $neutral-00, $alpha: 0.2);

        &_list {
          &_step-link {
            color: $neutral-00;
            font-size: 18px;
            opacity: 0.5;

            i {
              font-size: 12px;
              vertical-align: middle;
            }

            &--is-active {
              opacity: 1;
              font-weight: 500;
            }

            &--is-ok {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// Tickets V2
.ticket-details {
  &__ticket-activity {
    .accordion-item {
      margin-bottom: map-get($space, base-3);
      border: none;

      .accordion-header {
        .accordion-button {
          border: none;
          border: 1px solid $neutral-10;
          border-radius: 5px;
        }
      }

      .accordion-body {
        padding: 0;
      }
    }
  }
}

// _dropdown.scss (ENGFRONT-3440) - (Dropdown navigation & select with searcher)///////////////////////////////////////
//
// Dropdown navigation
.dropdown {
  .dropdown-menu {
    border-radius: 2px !important;

    .dropdown-item {
      color: $primary-dark;
    }
  }

  .dropdown-toggle {
    &::after {
      content: '\f107';
      @include font-awesome-regular();
      border: none;
      vertical-align: initial;
    }
  }

  .dropdown-actions-table {
    border: none;
    background: none;

    &::after {
      display: none;
    }
  }
}

// Select with searcher
.xen-filter-dropdown {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  align-items: center;

  .p-dropdown-label,
  .p-dropdown-item {
    font-size: map-get($font-size, body);
    color: $text-dark !important;
  }

  .p-dropdown-label {
    width: 235px;
  }

  .p-dropdown-item {
    &.p-highlight {
      color: $neutral-00 !important;
      background: $primary !important;
    }
  }

  .p-overlay {
    left: initial !important;
    right: 0;
    width: 400px;

    .p-dropdown-panel {
      .p-dropdown-header {
        padding: map-get($space, base);

        .p-dropdown-filter-container {
          .p-dropdown-filter {
            border: none;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .p-dropdown-trigger {
    .p-icon-wrapper {
      .p-dropdown-trigger-icon {
        display: none;
      }

      &::before {
        @include font-awesome-regular();
        content: '\f078' !important;
        color: $text-medium;
      }
    }
  }

  &.--is-error {
    border-color: $error-medium !important;
  }

  &.--is-valid {
    border-color: $success-medium !important;
  }
}

// _spinner.scss (ENGFRONT-3445)//////////////////////////////////////////////////////////////////////////////////////
//
.spinner {
  position: fixed;
  z-index: 100;
  background-color: rgba($color: $primary-dark, $alpha: 0.4);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .fa-spinner-third {
    font-size: 7rem;

    &::before {
      color: $primary;
    }

    &::after {
      color: $text-white;
    }
  }
}

//Format tables at cases aina response
.aina-table {
  @extend .table; // Extend the styles of the Bootstrap table class.

  margin: 1rem 0 !important;
  background-color: transparent !important;
  &.table,
  &.table > thead,
  &.table > thead > tr,
  &.table > thead > tr > th,
  &.table > tbody,
  &.table > tbody > tr,
  &.table > tbody > tr > td {
    background-color: transparent !important;
    border: 1px solid $neutral-40 !important;
  }
}

.code-snippet {
  background-color: $neutral-00;
  padding: 8px;
  margin: 8px 0;
  overflow-x: auto;

  pre {
    margin: 0;
    font-family: 'Fira Code', 'Courier New', Courier, monospace;
    font-size: 13px;
    line-height: 1.4;
  }

  code {
    background-color: transparent;
    color: inherit;
    padding: 0;
  }
}

.copy-data {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: map-get($space, base-2);
  background: transparent;
  color: $text-dark;
  border: 1px solid $text-dark;
  border-radius: 2px;
  padding: 10px 20px;
  font-size: map-get($font-size, body);
  font-weight: map-get($font-weight, medium);
  cursor: pointer;
  text-decoration: none; /* Para eliminar el subrayado del enlace */
  transition: background-color 0.3s ease;
  height: 32px;
  min-width: 32px;

  &:hover {
    color: lighten($text-dark, 10%);
    border: 1px solid lighten($text-dark, 10%);
    background: map-get($neutral, 05);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($primary, 0.5);
  }

  &:active {
    background-color: darken($text-dark, 15%);
    border: 1px solid darken($text-dark, 15%);
  }

  &.is-loading::before {
    border-color: $text-dark transparent $text-dark transparent;
  }
}

// old _modal.scss (ENGFRONT-3442) - (modals not changed by Storybook & Sidebar right (B2B))/////////////////////////////
//
.xen-modal {
  .modal-dialog {
    .modal-content {
      border-radius: 4px !important;

      .modal-header {
        padding: map-get($space, base-6);

        .modal-title {
          color: $text-dark !important;
          font-size: map-get($font-size, xlarge) !important;
          font-weight: map-get($font-weight, regular) !important;
        }

        .modal-subtitle {
          font-size: map-get($font-size, body);
          color: $text-light !important;
          padding: 0;
          margin: 0;
        }

        .btn-close {
          outline: none;
          box-shadow: none;

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .modal-body,
      .modal-footer {
        padding: map-get($space, base-6);
      }
    }
  }
}

// Sidebar right (B2B)
.xen-sidebar {
  &__title {
    color: $text-dark !important;
    font-size: map-get($font-size, xlarge) !important;
    font-weight: map-get($font-weight, regular) !important;
    margin-bottom: map-get($space, base-5);
  }

  &.p-sidebar-right {
    width: 50rem;
  }

  .p-sidebar-close {
    &:hover {
      color: $text-dark !important;
    }
  }
}

// old _input.scss (ENGFRONT-3441) - (inputs not changed by Storybook & Header searcher autocomplete)///////////////////
//
//General styles of old inputs
.form-label,
.form-select,
.form-control,
textarea {
  border-color: $neutral-20 !important;
  border-radius: 2px !important;
}

.form-control,
.form-select {
  height: 40px;
}

.form-label {
  font-weight: 500;
}

// Header searcher autocomplete
.header-autocomplete {
  .p-element {
    border-radius: 2px;
  }

  .p-inputwrapper-focus {
    border-radius: 2px;
  }

  .p-autocomplete {
    &-input {
      background: rgba($color: $text-white, $alpha: 0.2);
      outline: none;
      border: none;
      border-radius: 2px;
      height: 34px;
      padding: map-get($space, base) map-get($space, base-2);
      font-size: map-get($font-size, body);
      font-weight: map-get($font-weight, regular);

      @media screen and (max-width: 1024px) {
        border: 1px solid $neutral-20;
      }
    }

    ::placeholder {
      color: $text-white;

      @media screen and (max-width: 1024px) {
        color: $text-dark;
      }
    }

    input {
      width: 11rem;
      transition: width 0.2s linear;
      color: $text-white;

      @media screen and (max-width: 1024px) {
        border: 1px solid $neutral-20;

        ::placeholder {
          color: $text-dark;
        }
      }

      &:focus {
        background: $bg-primary;
        width: 20rem;
        transition: width 0.2s linear;
        color: $text-dark;
      }
    }

    .p-autocomplete-panel {
      background: $bg-primary;
      border-radius: 0 0 2px 2px;
      width: 550px;
      min-height: auto;
      max-height: 400px !important;

      .header-searches {
        font-size: map-get($font-size, small);
        font-weight: map-get($font-weight, bold);
        color: $text-medium;
        text-transform: uppercase;
        margin-bottom: 0;
        padding: 12px 12px 5px 12px;
      }
    }

    .p-highlight {
      background: $info-light !important;
    }

    .p-autocomplete-item {
      font-size: map-get($font-size, body);
      font-weight: map-get($font-weight, regular);
      color: $text-medium;
      cursor: none;
      pointer-events: none;
      padding: 0 !important;

      &:hover {
        background: $info-light !important;
      }

      .item {
        padding: map-get($space, base-3);

        &:hover {
          background: $bg-helper;
          cursor: pointer;
        }

        &.isActive {
          pointer-events: all;
        }

        .item-logo {
          width: 50px;
          height: 40px;
          object-fit: contain;
          padding: 5px;
          margin-right: 5px;
          border-radius: 2px;
        }

        .company-name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 360px;
          font-size: map-get($font-size, medium);
          font-weight: map-get($font-weight, medium);
          color: $text-dark;
          line-height: 20px;
        }

        .info-company {
          font-size: map-get($font-size, small);
          font-weight: map-get($font-weight, regular);
          color: $text-medium;
          margin-bottom: 0;
        }
      }
    }

    .p-autocomplete-panel {
      .item-searcher {
        font-size: map-get($font-size, mediun);
        font-weight: map-get($font-weight, medium);
        margin-bottom: 0;
      }
    }
  }

  &.--is-public {
    background: $bg-secondary;
    color: $text-dark;

    .p-autocomplete {
      &-input {
        background: $bg-secondary;
        color: $text-dark;
      }
    }

    ::placeholder {
      color: $text-dark;
    }

    input {
      &:focus {
        background: $bg-secondary;
        width: 20rem;
        transition: width 0.2s linear;
        color: $text-dark;
      }
    }
  }
}

// _tabs.scss (ENGFRONT-3446) - (Onboarding tabs, partner profile tabs & connections list/cols tabs)///////////////////
//
// Onboarding tabs
.tgx-onboarding-hori-tabs {
  .nav-tabs {
    border-bottom: none;
    flex-wrap: nowrap;

    .nav-link {
      padding: 1rem;
      width: 100%;
      border: none;
      color: $primary-dark !important;
      border-right: 3px solid $text-white;
      border-radius: 3px;
      margin-bottom: 0;

      .tgx-onboarding-hori-tabs__title {
        h5 {
          font-weight: bold;
        }

        .tgx-onboarding-hori-tabs__title-dev-status {
          padding: 10px;
          background-color: #f3f6fe;
          border-radius: 3px;
          min-height: 58px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .tgx-onboarding-hori-tabs__title-dev-status_method {
            color: $primary-dark;
            font-weight: bold;
          }

          .tgx-onboarding-hori-tabs__title-dev-status_status {
            font-weight: bold;

            &.no-started {
              color: #fa6400;
            }
          }
        }
      }

      &.success.active {
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          left: 50%;
          bottom: -20px;
          transform: translate(-50%);
          border-width: 30px 29.5px 0 29.5px;
          border-color: #f7fbf5 transparent transparent transparent;
        }
      }

      &.active {
        transition: none;
        position: relative;
        margin-bottom: 0;
        padding-bottom: 1rem;
        background-color: #e3e9f9;
        border: none;
        border-right: 2px solid $text-white;

        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          left: 50%;
          bottom: -20px;
          transform: translate(-50%);
          border-width: 30px 29.5px 0 29.5px;
          border-color: #e3e9f9 transparent transparent transparent;
        }
      }

      &.success {
        border: none;
        border-right: 3px solid $text-white;
        background-color: #f7fbf5;

        .tgx-onboarding-hori-tabs__title {
          h5 {
            color: $success-medium;
          }
        }

        &::after {
          display: none;
        }
      }

      &.disabled {
        border: none;
        border-right: 3px solid $text-white;

        .tgx-onboarding-hori-tabs__title {
          opacity: 0.5;
        }
      }

      &:first-child {
        border-left: 3px solid $text-white;
      }
    }
  }

  .tab-content {
    .tab-pane {
      &.fade {
        transition: opacity 0s linear;
      }
    }
  }
}

.tgx-onboarding-ver-tabs {
  .nav-pills {
    width: 350px;

    .nav-item {
      .nav-link {
        border-radius: 4px;
        color: $text-medium;
        font-size: 0.9rem;
        margin-bottom: 1rem;

        &.active {
          background-color: $neutral-05;
          color: $primary;
          font-weight: bold;
          border: 1px solid $primary;

          .tgx-onboarding-ver-tabs_title_icon {
            &::after {
              content: '\f058';
              font-size: 1.2rem;
              @include font-awesome-regular();
              color: #d2d9e1;
            }
          }
        }

        &.working {
          background-color: rgba($color: #fa6400, $alpha: 0.1);
          color: #fa6400;
          font-weight: bold;
          border: 1px solid #fa6400;

          .tgx-onboarding-ver-tabs_title_icon {
            &::after {
              content: '\f017';
              font-size: 1.2rem;
              @include font-awesome-regular();
              color: #fa6400;
            }
          }
        }

        &.error {
          background-color: rgba($color: $error-medium, $alpha: 0.1);
          color: $error-medium;
          font-weight: bold;
          border: 1px solid $error-medium;

          .tgx-onboarding-ver-tabs_title_icon {
            &::after {
              content: '\f057';
              font-size: 1.2rem;
              @include font-awesome-regular();
              color: $error-medium;
            }
          }
        }

        &.valid {
          background-color: #f7fbf5;
          color: $success-medium;
          font-weight: bold;
          border: 1px solid $success-medium;

          .tgx-onboarding-ver-tabs_title_icon {
            &::after {
              content: '\f058';
              font-size: 1.2rem;
              @include font-awesome-regular();
              color: $success-medium;
            }
          }
        }
      }

      // Subtabs
      .tgx-onboarding-ver-tabs_subtabs-link {
        text-decoration: none;
        color: $text-medium;
        padding: 0 0 10px 0;

        &.active {
          color: $primary-dark;
          font-weight: bold;
          border: none;
          background: none;
        }

        &.success {
          color: $success-medium;
          font-weight: bold;

          &::after {
            content: '\f00c';
            @include font-awesome-regular();
            color: $success-medium;
          }
        }
      }

      .tgx-onboarding-ver-tabs_selected {
        margin-top: -5px;
        padding-bottom: 10px;

        .text-bg-tech {
          background-color: rgba($color: #b620e0, $alpha: 0.2);
          color: #b620e0;
        }
      }
    }

    .tgx-onboarding-ver-tabs_tip {
      margin-top: auto;

      p {
        margin-bottom: 0;
      }
    }
  }

  &.confetti {
    background-image: url('/assets/img/pages/dashboard/onboarding/onboard_confetti.svg');
  }
}

// Partner profile tabs
.xen-orgtype-style-tabs {
  font-family: 'Roboto', sans-serif !important;

  .p-tabview-nav-container {
    .p-tabview-nav-content {
      .p-tabview-nav {
        gap: 0.5rem !important;
        margin-bottom: 0;
        padding-left: 0;

        .p-highlight {
          .p-tabview-nav-link {
            text-decoration: none;
            background: $neutral-00;
            border-color: 1px solid $neutral-10;
            padding-bottom: map-get($space, base-4);
            margin: 0 0 -1px 0;

            &:focus {
              box-shadow: none;
            }
          }
        }

        li {
          display: flex;
          align-items: flex-end;

          .p-tabview-nav-link {
            text-decoration: none;
            background: $bg-secondary;
            border: 1px solid $neutral-10;
            min-width: 210px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: map-get($space, base-2);
            margin: 0;

            &:focus {
              box-shadow: none;
            }

            .title {
              font-size: map-get($font-size, body);
              font-weight: map-get($font-weight, bold);
              margin-bottom: 0;
              color: $text-dark;
            }

            .subtitle {
              font-size: map-get($font-size, small);
              font-weight: map-get($font-weight, regular);
              color: $text-medium;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .p-tabview-panels {
    margin-top: -1px;
    border: 1px solid $neutral-10 !important;
    padding: map-get($space, base-4) !important;
  }

  &__company-status {
    &__icon {
      font-size: 32px;
      margin-right: map-get($space, base-2);
      color: $error-medium;

      &.fa-upload {
        color: $neutral-40;
      }

      &.--not-available {
        color: $neutral-40 !important;
      }

      &.--is-test {
        color: $primary-lighten !important;
        background-color: $primary-lightest !important;
      }
    }

    .fa-tower-broadcast {
      padding: map-get($space, base-3);
      border-radius: 100px;
      background-color: $success-light;
      color: $success-dark;
      font-size: 20px;
    }

    &__title {
      font-size: map-get($font-size, body);
      font-weight: map-get($font-weight, medium);
      margin-bottom: 0;
    }

    &__subtitle {
      font-size: map-get($font-size, small);
      font-weight: map-get($font-weight, regular);
      color: $text-medium;
      margin-bottom: map-get($space, base-2);
    }
  }
}

// Connections list/cols tabs
.xen-tabs-showview {
  .p-tabview-nav-container {
    .p-tabview-nav-content {
      .p-tabview-nav {
        justify-content: flex-end;
        margin-bottom: map-get($space, base-3);

        .p-highlight {
          .p-tabview-nav-link {
            background-color: $bg-secondary;
            color: $text-dark;
          }
        }

        .p-tabview-nav-link {
          border-radius: 2px;
          border: none;
          padding: map-get($space, base-3);
          background: none;
          color: $text-dark;
          text-decoration: none;
        }
      }
    }
  }

  .p-tabview-panels {
    padding: 0 !important;
    border: none !important;
  }
}

// _slider.scss (ENGFRONT-3446) - (Dashboard, Range slider (search contract - Billing and modal config - Speed)////////
//
// Dashboard
.tgx-card-slider {
  .card-body {
    .carousel {
      position: initial;

      &:focus {
        outline: none;
      }

      .carousel-inner {
        &:focus {
          outline: none;
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        width: 30px;
        height: 30px;
      }

      .carousel-control-prev {
        top: 40px;
        left: 92%;

        @media (max-width: 425px) {
          left: 40%;
          top: 34px;
        }

        .carousel-control-prev-icon {
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: '\f137';
            @include font-awesome-regular();
            color: $primary-dark;
            font-size: 1.5rem;
          }
        }
      }

      .carousel-control-next {
        top: 40px;
        left: 97%;

        @media (max-width: 425px) {
          left: 50%;
          top: 34px;
        }

        .carousel-control-next-icon {
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: '\f138';
            @include font-awesome-regular();
            color: $primary-dark;
            font-size: 1.5rem;
          }
        }
      }

      .carousel-indicators {
        display: none;
      }
    }
  }
}

// Range slider
.xen-range {
  background: $neutral-20 !important;

  .p-slider-range {
    background: $primary !important;
  }

  .p-slider-handle {
    border: 1px solid $primary !important;

    &:hover {
      background: $neutral-00 !important;
      border-color: $primary !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

// _xen-table.scss (ENGFRONT-3435) /////////////////////////////////////////////////////////////////////////////////////
//
$table: '.xen-table';

#{$table} {
  background-color: transparent;
  border-radius: 2px;
  &.is-full {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid map-get($neutral, 10);
    &.warning {
      background-color: $warning-light;
    }
    &.error {
      background-color: $error-light;
    }
  }
  td {
    padding: map-get($space, base-2) map-get($space, base-3);
    font-size: map-get($font-size, body);
    color: $text-dark;
  }

  .popover {
    border-radius: 2px !important;
  }

  th {
    background-color: map-get($neutral, 5);
    padding: map-get($space, base-2) map-get($space, base-3);
    text-align: left;
    font-size: map-get($font-size, body);
    font-weight: map-get($font-weight, medium);
    color: $text-medium;

    &.sortable {
      color: $text-medium;
      text-decoration: none;
      align-items: center;
      gap: map-get($space, base-2);
      cursor: pointer;
      &::after {
        content: '\f0dc';
        @include font-awesome-solid();
        margin-top: -4px;
        margin-left: 8px;
      }

      &--up::after {
        content: '\f0de';
        @include font-awesome-solid();
        height: 14px;
      }
      &--down::after {
        content: '\f0dd';
        @include font-awesome-solid();
        margin-top: -4px;
      }
    }
    a.sortable {
      color: $text-medium;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: map-get($space, base-2);
      cursor: pointer;
      &--up::after {
        content: '\f0de';
        @include font-awesome-solid();
        height: 14px;
      }
      &--down::after {
        content: '\f0dd';
        @include font-awesome-solid();
        margin-top: -4px;
      }
    }
  }
}

// _xen-multiselect.scss (ENGFRONT-3481) ///////////////////////////////////////////////////////////////////////////////
//
$multiselect: '.xen-multiselect-dropdown';

#{$multiselect} {
  width: 100%;
  border-color: $neutral-20 !important;
  align-items: center;
  color: $text-light;
  font-size: map-get($font-size, body) !important;
  border-radius: 2px !important;
  height: 40px;
  background: $neutral-00;

  .p-multiselect-label-container {
    .p-multiselect-label {
      width: 100%;

      .p-multiselect-token {
        background: $info-light !important;
        color: $text-dark !important;
        border-radius: 2px !important;
        margin-right: map-get($space, base);

        .p-icon-wrapper {
          .p-multiselect-token-icon {
            display: none !important;
          }

          &::before {
            @include font-awesome-regular();
            content: '\f00d' !important;
            margin-left: map-get($space, base);
            cursor: pointer;
          }
        }
      }
    }
  }

  .p-multiselect-panel {
    .p-multiselect-items-wrapper {
      .p-multiselect-items {
        padding-left: 0;
        .p-element {
          .p-multiselect-item {
            font-size: map-get($font-size, body) !important;
            color: $text-dark;

            &.p-highlight {
              color: $text-dark;
              background-color: $neutral-00;
            }
          }
        }
      }
    }
  }

  &.--is-error {
    border-color: $error-medium !important;
  }

  &.--is-valid {
    border-color: $success-medium !important;
  }

  .p-multiselect-trigger-icon {
    font-size: map-get($font-size, small);
    color: $text-dark;
  }
}

// Temporal multiselect style logging
.temp-multi-styles {
  border: none !important;
  background: transparent !important;
  height: 21px;
  max-width: 250px !important;

  .p-multiselect-label-container {
    .p-placeholder {
      color: $primary !important;
      text-decoration: underline;
      font-weight: map-get($font-weight, bold);
      height: 20px;
      padding: 0;
    }
  }
  .p-multiselect-items-wrapper {
    min-width: 250px;
  }
}

.p-dropdown-items {
  padding-left: 0 !important;
}

/* Travelsoft Footer
------------------------*/
:root {
  --forground-color: #000;
  --travesoft-brand--atcore: #009b67;
  --travesoft-brand--eventiz: #6b6860;
  --travesoft-brand--orchestra: #ed272a;
  --travesoft-brand--tigerbay: #f47216;
  --travesoft-brand--traffics: #162c54;
  --travesoft-brand--travelgatex: #0b59de;
  --travesoft-brand--travelcompositor: #1692e1;
  --travesoft-brand--tct: #3fb4be;
  --travesoft-brand--ts: #00a1e6;
  --travesoft-brand--gradient-dark: #1f8868;
  --travesoft-brand--gradient-light: #249987;
}

.travelsoft-footer {
  color: var(--forground-color);
  font-family: 'Roobert', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  background-color: $neutral-05;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.brand-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;
  gap: 16px;
}

.brand-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1;
}

.brand-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--forground-color);
  cursor: pointer;
  gap: 4px;
  text-decoration: none;
}

.icon-travelsoft {
  width: 8.2rem;
  min-width: 9rem;
  height: 2.1rem;
}

.ts-group-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--forground-color);
  transition: color 0.2s;
  text-decoration: none;
}

.ts-group-label:hover {
  color: var(--travesoft-brand--gradient-dark);
}

.animated-blason {
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
}

.portfolio-blason-wrap {
  width: 26px;
  height: 26px;
  position: absolute;
}

.portfolio-blason {
  display: block;
}

svg:not(:root) {
  overflow: hidden;
}

.blason-main,
.blason-hidden {
  position: absolute;
  left: 2px;
}

.blason-main {
  top: 2px;
}

.blason-hidden {
  top: 34px;
}

.atcore-blason {
  color: var(--travesoft-brand--atcore);
}
.eventiz-blason {
  color: var(--travesoft-brand--eventiz);
}
.orchestra-blason {
  color: var(--travesoft-brand--orchestra);
}
.tigerbay-blason {
  color: var(--travesoft-brand--tigerbay);
}
.traffics-blason {
  color: var(--travesoft-brand--traffics);
}
.travelgatex-blason {
  color: var(--travesoft-brand--travelgatex);
}
.travelcompositor-blason {
  color: var(--travesoft-brand--travelcompositor);
}
.tct-blason {
  color: var(--travesoft-brand--tct);
}
.ts-blason {
  color: var(--travesoft-brand--ts);
}

.brand-link .blason-main.blason-init {
  top: 2px;
  // animation: reversein 0.35s;
}

.brand-link .blason-hidden {
  top: 34px;
  // animation: reverseout 0.35s;
}

.brand-link:hover .blason-main {
  top: -34px;
  // animation: moveout 0.35s;
}

.brand-link:hover .blason-hidden {
  top: 2px;
  // animation: movein 0.35s;
}

@keyframes moveout {
  from {
    top: 2px;
  }
  to {
    top: -34px;
  }
}

@keyframes movein {
  from {
    top: 34px;
  }
  to {
    top: 2px;
  }
}

@keyframes reverseout {
  from {
    top: 0;
  }
  to {
    top: 34px;
  }
}

@keyframes reversein {
  from {
    top: -34px;
  }
  to {
    top: 0;
  }
}

@font-face {
  font-family: 'Roobert';
  src: url('https://cdn.prod.website-files.com/6643aa2608d3ee07c7a58741/6644c4e934541f5476a02c0e_RoobertPRO-Regular.woff2')
    format('woff2');
  font-weight: 400;
  font-display: swap;
}

@media screen and (max-width: 991px) {
  .brand-bar {
    gap: 24px;
  }
}

@media screen and (max-width: 767px) {
  .brand-bar {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .brand-links {
    gap: 40px;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 20px;
  }
}

@media screen and (max-width: 479px) {
  .brand-bar {
    gap: 24px;
  }

  .brand-links {
    flex-direction: column;
  }

  .brand-link {
    justify-content: flex-start;
  }
}

/* Picklist API Settings > Client Settings */
.p-picklist-list-wrapper {
  width: 50% !important;
}
