/* Admin main imports*/
@import 'bootstrap/scss/bootstrap.scss';
@import './variables.scss';
@import './tgx.scss';

.table-members {
  .p-datatable-wrapper {
    overflow: visible !important;
  }
}

.members-body {
  overflow: visible;
}
